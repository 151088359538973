






























































import {Component, Prop, Vue} from 'vue-property-decorator';
import {GetAllPaymentMethods, MakePaymentMethodPrimary, RemovePaymentMethod} from "../../graphql/auth";
import Template from "../templates/Template.vue";
import AddNewCard from "@/components/subscription/AddNewCard.vue";


@Component({
  components: {Template},
})
export default class PaymentMethods extends Vue {

  savedCards: Array<any> = [];
  payment_method: string | null = null;

  @Prop({default: true, required: false})
  showCard!: boolean

  @Prop({default: false, required: false})
  isLoading!:boolean

  get me() {
    return this.$store.state.me;
  }

  get workspaceSubscription() {
    return this.$store.state.workspace.currentSubscription;
  }



  canRemovePrimaryCard(primary: boolean) {
    if(this.workspaceSubscription && primary) {
      return false;
    } else {
      return true;
    }
  }

  mounted() {
    if(this.me.stripe_customer_id) {
      this.getAllPaymentMethods();
    }


    this.$events.listen('allcards', (eventData: any) => {
      this.savedCards = eventData;
      let defaultPaymentMethod = this.savedCards.filter((card: any) => {
        return card.is_default;
      });
      if(defaultPaymentMethod.length > 0) {
        this.payment_method = defaultPaymentMethod[0].id;
      }


      if(!this.payment_method) {
        this.setPaymentMethodPrimay(this.savedCards[0].id)
      }
    })
  }

  getAllPaymentMethods() {
    this.$apollo.mutate({
      mutation: GetAllPaymentMethods,
      variables: {
        stripe_customer_id: this.me.stripe_customer_id
      }
    }).then((res: any) => {
      this.savedCards = res.data.getAllPaymentMethods;
      let defaultPaymentMethod = this.savedCards.filter((card: any) => {
        return card.is_default;
      });
      if(defaultPaymentMethod.length > 0) {
        this.payment_method = defaultPaymentMethod[0].id;
      }


      if(this.payment_method) {
        this.$events.fire('defaultPaymentMethod', this.payment_method);
      }



    })
  }

  setPaymentMethodPrimay(paymentMethod: string) {
    this.$apollo.mutate({
      mutation: MakePaymentMethodPrimary,
      variables: {
        stripe_customer_id: this.me.stripe_customer_id,
        paymentMethod: paymentMethod
      }
    }).then((res: any) => {
      this.savedCards = res.data.setPaymentMethodPrimary;
      this.payment_method = this.savedCards.filter((card: any) => {
        return card.is_default;
      })[0].id;
      this.$events.fire('defaultPaymentMethod', this.payment_method);
    }).finally(() => {
      
     
    });
  }

  removePaymentMethod(paymentMethod: string) {
    this.$apollo.mutate({
      mutation: RemovePaymentMethod,
      variables: {
        paymentMethod: paymentMethod
      }
    }).then((_) => {
      this.savedCards = this.savedCards.filter((card: any) => {
        return card.id != paymentMethod;
      })
      this.$buefy.toast.open({
        message: 'Payment method removed',
        position: 'is-bottom-right',
        type: 'is-black',
      });
    })
  }

  openAddNewCard() {
    this.$buefy.modal.open({
      component: AddNewCard,
      props: {
        open: true
      },
      width: '480px',
      parent: this,
    });
  }
}
