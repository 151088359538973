












































import {Component, Vue} from 'vue-property-decorator'
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import {CreateSetupIntent} from "@/graphql/WorkspaceSubscription";
import {AddNewPaymentMethod, MeQuery} from "../../graphql/auth";

@Component({
  components:{StripeElementCard}
})
export default class AddNewCard extends Vue {

  cardNumber: any | null = null
  cardExpiry: any | null = null
  cardCvc: any | null = null
  cardNumberError: string | null = null;
  cardExpiryError: string | null = null;
  cardCvcError: string | null = null;
  cardNumberErrorFlag: boolean = true;
  cardExpiryErrorFlag: boolean = true;
  cardCvcErrorFlag: boolean = true;
  isLoading: boolean = false;

  get me() {
    return this.$store.state.me;
  }

  get stripeElements () {
    //@ts-ignore
    return this.$stripe.elements();
  }

  get isDisabled() {
    if(!this.cardNumberErrorFlag && !this.cardExpiryErrorFlag && !this.cardCvcErrorFlag) {
      return false;
    }
    return true;
  }

  mounted() {
    const style = {
      style: {
        base: {
          iconColor: "#000",
          color: "#000",
          fontWeight: "800",
          fontFamily: "Press Start 2P",
          fontSize: "22px",
          fontSmoothing: "antialiased",
          ":-webkit-autofill": {
            color: "#fce883"
          },
          "::placeholder": {
            color: "green"
          }
        },
        invalid: {
          iconColor: "#FFC7EE",
          color: "red"
        }
      }
    };
    this.cardNumber = this.stripeElements.create('cardNumber', {style});
    this.cardNumber?.mount('#card-number');
    this.cardExpiry = this.stripeElements.create('cardExpiry', {style});
    this.cardExpiry.mount('#card-expiry');
    this.cardCvc = this.stripeElements.create('cardCvc', {style});
    this.cardCvc?.mount('#card-cvc');



    this.cardNumber.on('change', (event: any) => {
      if(event.complete) {
        this.cardNumberErrorFlag = false;
      }else{
        this.cardNumberErrorFlag = true;
        if(event.error) {
          this.cardNumberError = event.error.message
        }else{
          this.cardNumberError = null;
        }
      }

    })

    this.cardExpiry.on('change', (event: any) => {
      if(event.complete) {
        this.cardExpiryErrorFlag = false;
      }else{
        this.cardExpiryErrorFlag = true;
        if(event.error) {
          this.cardExpiryError = event.error.message
        }else{
          this.cardExpiryError = null;
        }
      }

    })

    this.cardCvc.on('change', (event: any) => {
      if(event.complete) {
        this.cardCvcErrorFlag = false;
      }else{
        this.cardCvcErrorFlag = true;
        if(event.error) {
          this.cardCvcError = event.error.message
        }else{
          this.cardCvcError = null;
        }
      }

    })



    this.stripeElements.on('change', (_event: any) => {

    })
  }

  destroyed() {
    this.cardNumber.destroy();
    this.cardCvc.destroy();
    this.cardExpiry.destroy();
  }

  createSetupIntent() {
    this.isLoading = true;
    this.$apollo.mutate({
      mutation: CreateSetupIntent,
      refetchQueries: [{query: MeQuery}]
    }).then((res: any) => {
     
      if(res.data.createSetupIntent && res.data.createSetupIntent.client_secret) {
        this.confirmCardSetup(res.data.createSetupIntent.client_secret);
      }

    }).catch((error: any) => {
      //this.isLoading = false;
      Vue.prototype.$consoleLog(error);
    })
  }

  confirmCardSetup(secret: string) {
    //@ts-ignore
    this.$stripe.confirmCardSetup(secret, {
      payment_method: {
        card: this.cardNumber,
        billing_details: {
          name: this.me.name,
          email: this.me.email,
        }
      }
    }).then((res: any) => {
      if(res.error) {
        this.$buefy.toast.open({
          message: res.error.message,
          position: 'is-bottom-right',
          type: 'is-danger',
          duration: 3000
        });
        this.isLoading = false;
      }else{
        this.addNewPaymentMethod(res.setupIntent.payment_method);
      }


    }).catch((error: any) => {
      //this.isLoading = false;
      Vue.prototype.$consoleLog(error);
    });
  }

  addNewPaymentMethod(payment_method: string) {
    this.$apollo.mutate({
      mutation: AddNewPaymentMethod,
      variables: {
        stripe_customer_id: this.me.stripe_customer_id,
        paymentMethod: payment_method
      },
    }).then((res: any) => {
      this.isLoading = false;
      this.$events.fire('allcards', res.data.addNewCard);
      this.$buefy.toast.open({
        message: 'New card added',
        position: 'is-bottom-right',
        type: 'is-black',
      });
      //@ts-ignore
      this.$parent.close();
    }).catch((error: any) => {
      //this.isLoading = false;
      Vue.prototype.$consoleLog(error);
    })
  }
}

